import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/diarcastro/clients/cure-foundation/looking-forward/src/templates/static-content-template.js";
import ExpertsList from '@organisms/experts-list';
import AboutLogos from "@organisms/about-logos";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InlineItems = makeShortcode("InlineItems");
const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <section>
      <h2>{`À propos de Tourné vers l’avenir`}</h2>
      <p>{`Au moment où les personnes atteintes de cancer ont terminé leur traitement actif et sont impatientes de retourner à leur vie quotidienne, il est très fréquent qu’elles se posent des questions sur leur rétablissement ou ressentent de l’incertitude à propos des prochaines étapes. Se rétablir à la suite d’un traitement contre le cancer n’est pas seulement une convalescence. Il s’agit également de la guérison physique, émotionnelle et spirituelle. Le programme Tourné vers l’avenir procure du soutien et des ressources pour aider les patients atteints de cancer en rétablissement à reconstruire leur vie et à aller de l’avant après le traitement. Du soutien et des ressources sont aussi offerts aux familles et aux proches aidants.`}</p>
      <p>{`Le programme Tourné vers l’avenir s’efforce de respecter le caractère unique de chaque expérience de rétablissement. Nous considérons que la convalescence est un processus fondamentalement guidé par l’expérience personnelle. Le programme met l’accent sur les sujets et les problèmes identifiés importants par les patients collaborateurs dans le processus de rétablissement. Il permet aux patients d’identifier où ils doivent concentrer leurs efforts pour leur rétablissement. Nous trouvions essentiel que les personnes qui avaient vécu un rétablissement fassent partie de la collaboration avec les professionnels de la santé et les chercheurs dans le développement du programme Tourné vers l’avenir. Les anciens patients ont partagé leur perspective, défini les priorités et collaboré à la maquette et au format. Nous croyons fermement que grâce à leur engagement, ce programme sera une source d’information pratique et pertinente.`}</p>
      <h3>{`Rosana Faria, L. Ps.`}</h3>
      <p>{`Chercheuse principale, Département d’oncologie, Centre hospitalier de St. Mary`}</p>
      <h3>{`Susan Law, Ph. D.`}</h3>
      <p>{`Cochercheuse principale, Centre de recherche de St. Mary`}</p>
      <br />
    </section>
    <section>
      <h2>{`Remerciements`}</h2>
      <h3>{`Le site web interactive tourné vers l'avenir`}</h3>
      <p>{`Le site web interactif Tourné vers l’avenir a été rendu possible grâce au financement de la Fondation CURE. Nous sommes reconnaissants envers Diane Proulx-Guerrera et son équipe pour leur compréhension et leur soutien de notre vision pour cette ressource. Nous aimerions aussi remercier Cynda Heward de la Fondation de l’hôpital St. Mary pour son leadeurship et son assistance dans la proposition de ce projet.`}</p>
      <p>{`Tous nos remerciements à Dre Mary Jane Esplen de l’Université de Toronto, Dre Donna Stern du Centre hospitalier de St. Mary et Dr Luis Souhami du CUSM pour leur temps et leur expertise dans la révision du contenu. Nous remercions également Lowell Gasoi pour le partage de ses connaissances et de ses habiletés dans l’élaboration du contenu. À notre conseillère Mona Magalhaes, merci de ta disponibilité constante pour répondre à nos questions. Nous sommes reconnaissants envers Céline Bailey pour la traduction du contenu et Maïa Magalhaes-Filion pour ses habiletés de recherche et pour la relecture finale du français. Nous aimerions également remercier Linda Tracey et son équipe web pour leur aide dans la réalisation de ce projet, pour la mise au point du contenu et pour la coordination globale à ce projet.
Et finalement, mais non la moindre, l'équipe de Evolving Web : Suzanne, Rakhi, Annika et Diego. Nous n’aurions pu y arriver sans vous. Nous vous remercions pour votre créativité et votre sens artistique, votre professionnalisme. Ce fut un réel plaisir de travailler avec vous.`}</p>
      <section className="books-container">
        <h3>{`La série originale de livrets`}</h3>
        <InlineItems className={'books'} mdxType="InlineItems">
          <p><img parentName="p" {...{
              "src": "/images/books/book-fr-1.jpg",
              "alt": "book 1"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-fr-2.jpg",
              "alt": "book 2"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-fr-3.jpg",
              "alt": "book 3"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-fr-4.jpg",
              "alt": "book 4"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-fr-5.jpg",
              "alt": "book 5"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-fr-6.jpg",
              "alt": "book 6"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-fr-7.jpg",
              "alt": "book 7"
            }}></img></p>
        </InlineItems>
      </section>
      <p>{`Nous sommes reconnaissants envers le Réseau de cancérologie Rossy pour le généreux soutien financier du programme initial Tourné vers l’avenir. Le Réseau de cancérologie Rossy (RCR) est un partenariat de la Faculté de médecine de l’Université McGill et ses hôpitaux d’enseignement : le Centre universitaire de santé McGill, l’Hôpital général juif et le Centre hospitalier de St. Mary. Lancé en 2012 grâce à un don transformateur de la Fondation de la famille Rossy, le RCR est une initiative des dix dernières années visant à bonifier la qualité du continuum des soins contre le cancer pour les patients des hôpitaux affiliés à l’Université McGill. L’amélioration de l’expérience des patients dans leur lutte contre le cancer est l’une des priorités du RCR. Le projet Tourné vers l’avenir couvre les aspects de la transition vers la survivance après le traitement. C’est une étape cruciale dans l’expérience du cancer du patient où il a besoin de soutien. Le RCR est fier d’appuyer cette initiative qui fournit de l’information et du soutien indispensables aux patients atteints de cancer qui s’adaptent à la « nouvelle normalité » de leur vie après le traitement.`}</p>
      <p>{`Nous aimerions également souligner le généreux soutien financier des autres commanditaires du programme initial Tourné vers l’avenir : les Instituts de recherche en santé du Canada et le comité des usagers du Centre hospitalier de St. Mary. Nous remercions spécialement la Fondation de l’hôpital St. Mary pour son appui constant envers la recherche, la formation et les services de santé. Merci au Centre de recherche de St. Mary, l’Université McGill et le Centre universitaire de santé McGill pour leur soutien indéfectible.`}</p>
      <p>{`L’initiative Tourné vers l’avenir n’aurait pas été possible sans la patience, les conseils et le soutien des patients collaborateurs et qui sont nos partenaires de recherche dans ce projet. Nous remercions notamment Monique Ferland, Laurie Hendren, George Michaels, Danielle Potas et Givette Volet pour leur rétroaction et leurs précieux renseignements. Nos remerciements aux professionnels de la santé membres de l’équipe de recherche de Tourné vers l’avenir : Rosana Faria (psychologue clinique en oncologie au Centre hospitalier de St. Mary), Susan Law (chercheuse principale au Centre de recherche de St. Mary), Tarek Hijal (radiooncologue au CUSM) et Joan Zidulka (oncologue au Centre hospitalier de St. Mary). Merci également à Mona Magalhaes (coordonnatrice du projet) et Maud Mazaniello (spécialiste bilingue des communications). Nos sincères remerciements à Linda Tracey pour son expertise en matière d’édition et de mise en page pour la production des livrets et à Céline Bailey pour relecture de la version française. Nous sommes reconnaissants envers Joël Montanez et William Langlais pour leur expertise professionnelle et technique dans la production de la vidéo de présentation. Merci à la Dre Sylvie Lambert de nous avoir partagé son travail sur l’intervention d’autogestion Coping Together qui aborde les besoins psychosociaux des couples touchés par le cancer. Le format de Tourné vers l’avenir s’est largement inspiré de cette série.`}</p>
      <p>{`Nous sommes redevables aux patients qui ont partagé leurs expériences de traitement et de vie qui suit la fin du traitement, et qui ont travaillé avec les professionnels de la santé et les équipes de recherche pour le choix des sujets contenus dans le programme Tourné vers l’avenir. Nous sommes honorés du privilège que vous nous avez accordé d’entendre vos histoires. Nous sommes également reconnaissants envers les professionnels de la santé qui ont partagé leurs expériences de soins des personnes atteintes de cancer.`}</p>
      <p>{`Nos sincères remerciements aux professionnels en oncologie qui ont soutenu le développement du matériel et qui ont offert leurs conseils d’experts : Karine Gimmig (Oncologie, CHSM), Marc Hamel (Oncologie psychosociale, CUSM), Sandra Grant (Oncologie, CHSM), Nancy Moscato (travailleuse sociale, CUSM), Maria Milioto (Cliniques d’évaluation et de réadaptation), Janet Steinberg (Oncologie, CHSM), Mei-Lin Yee (militante pour les droits des patients) et Joan Zidulka (Oncology, Centre hospitalier de St. Mary).`}</p>
      <AboutLogos mdxType="AboutLogos" />
    </section>
    <section>
      <h2>{`Rencontrez les experts`}</h2>
      <Section className="mt-6" mdxType="Section">
    <ExpertsList mdxType="ExpertsList" />
      </Section>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      